<template>
    <div id="sc-tickets-completed">
        <div id="sc-header">
            <h3>Tickets Completed</h3>
        </div>

        <!-- Loading Spinner -->
        <div v-if="ticketsAll.length == 0" class="sc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <v-client-table v-else :data="ticketsCompleted" :columns="columns" :options="options">
            <div class="sc-actions-container" slot="actions" slot-scope="{ row }">
                <b-icon-pencil-square class="sc-icon-edit" @click="onClickEditTicket(row.id)"></b-icon-pencil-square>
                <b-icon-trash class="sc-icon-delete" @click="onClickDeleteTicket(row.id)"></b-icon-trash>
            </div>
        </v-client-table>
       
        <!-- Modal - Delete Ticket -->
        <b-modal id="sc-modal-delete-ticket" ref="sc-modal-delete-ticket" size="md" title="Delete Ticket" centered hide-footer>
            <div style="display: flex; align-items: center; justify-content: center">
                <span style="margin-left: 40px"
                    >Are you sure to delete ticket <strong># {{ deleteTicketId }}</strong></span
                >
            </div>
            <b-button class="mt-4 sc-button-primary" block @click="deleteTicket">Delete</b-button>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./APICalls";
import util from "./../../util/util";

export default {
    name: "TicketsCompleted",
    data() {
        return {
            columns: ["id", "customer.company", "products", "linteItemsSku", "truck", "dateTime", "linteItemsQuantity", "carryOver", "actions", "quickbooksInvoice"],
            deleteTicketId: "",
            ticketsCompleted: [],
            ticketsAll: [],
            options: {
                perPage: 50,
                columnsDropdown: true,
                orderBy: {
                    column: "id",
                    ascending: false,
                },
                headings: {
                    id: "#",
                    "customer.company": "Customer",
                    linteItemsSku: "SKU",
                    linteItemsQuantity: "Quantity",
                    dateTime: "Date",
                    quickbooksInvoice: "QB #",
                    carryOver: "Prepay",
                },
                templates: {
                    linteItemsSku(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.item.sku + "\n";
                        });
                        return res;
                    },
                    linteItemsQuantity(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.quantity + "\n";
                        });
                        return res;
                    },
                    carryOver(h, ticket) {
                        return ticket.customer.carryOver;
                    },
                    dateTime(h, ticket) {
                        return util.formatTimestamp(ticket.createdAt);
                    },
                },
            },
        };
    },
    methods: {
        onClickDeleteTicket(id) {
            this.deleteTicketId = id;
            this.$refs["sc-modal-delete-ticket"].show();
        },
        onClickEditTicket(id) {
            this.$router.push({ path: "/edit-ticket/" + id });
        },
        async deleteTicket() {
            try {
                await APICalls.deleteTicket(this.deleteTicketId);
                this.$toasted.show(`Ticket # ${this.deleteTicketId} deleted`, { position: "bottom-center", className: "sc-toast-success", duration: 5000 });
                this.deleteTicketId = "";
                this.$refs["sc-modal-delete-ticket"].hide();
                this.getTickets();
            } catch (errResponse) {
                console.log(errResponse);
            }
        },
        async getTickets() {
            this.ticketsAll = [];
            try {
                let tickets = await APICalls.getTickets({ ticketType: "completed" });
                this.ticketsAll = tickets;
            } catch (e) {
                console.log(e);
                this.$router.push({ path: "/login" });
            }
        },
    },
    watch: {
        ticketsAll: function(val) {
            const ticketsCompleted = [];

            _.each(val, (ticket) => {
                let products = "";
                _.each(ticket.lineitems, (obj) => {
                    products += obj.item.name + "\n";
                });

                ticket.products = products;

                // Completed
                if (ticket.quickbooksInvoice && ticket.quickbooksInvoice.length != 0) {
                    ticketsCompleted.push(ticket);
                }
            });

            this.ticketsCompleted = ticketsCompleted;
        },
    },
    mounted() {
        this.getTickets();
    },
};
</script>

<style lang="scss">
#sc-tickets-completed {
    #sc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    .sc-actions-container {
        display: flex;
        align-items: center;

        .sc-icon-edit {
            width: 22px;
            height: 22px;
            margin-right: 20px;
            color: black;
            cursor: pointer;
        }

        .sc-icon-delete {
            width: 22px;
            height: 22px;
            margin-right: 20px;
            color: black;
            cursor: pointer;
        }

        .sc-button-primary {
            margin-top: 10px;
            width: 160px;
        }
    }

    .VueTables {
        .row {
            // Columns - Dropdown
            .dropdown {
                .dropdown-toggle {
                    background-color: #007bc4 !important;
                    height: 45px;
                }

                .dropdown-menu {
                    padding: 10px;
                    font-size: 18px;

                    input {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    table {
        tbody {
            tr {
                td {
                    white-space: pre;
                    word-wrap: break-word;
                }
                td:nth-child(2) {
                    font-size: 14px;
                }
                td:nth-child(4) {
                    font-size: 14px;
                }
                td:nth-child(5) {
                    max-width: 120px;
                    word-wrap: break-word;
                    overflow-wrap: break-word !important;
                    white-space: normal;
                }
                td:nth-child(6) {
                    font-size: 14px;
                }
                td:nth-child(7) {
                    text-align: center;
                }
            }
        }
    }
}
</style>
